<template>
	<div class="w-full h-full flex flex-col">
		<Header>
			<div>
				<h1>Velg din bruker</h1>
				<h3 v-if="users">Totalt {{users.length}} {{users.length > 1 ? 'brukere' : 'bruker'}}</h3>
			</div>
		</Header>
		
		<div class="flex-grow overflow-auto p-6 md:p-12">
			<div 
				v-if="loading == 'fetching'"
				class="h-full flex items-center justify-center">
				
				<div class="spinner"></div>
			</div>
			<div v-else-if="users">
				<div 
					v-for="user in users"
					:key="'user_'+user.username"
					@click="onUserClick(user)"
					class="border-b border-dashed border-beige-dark cursor-pointer">
					
					<UserRow
						:userPassed="user"
					/>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import PortalMixins from '@/mixins/PortalMixins';
	
	export default {
		mixins: [
			PortalMixins,
		],
		
		components: {
			'UserRow': () => import('@/components/users/UserRow.vue'),
		},
		
		computed: {
			users(){
				if ( !this.$store.getters.getUsers || !this.$store.getters.getUsers.length) {
					return null;
				}
				
				const navigatorLanguage = this.account.locale || 'no';
				
				
				const users = this.$store.getters.getUsers
					.map( user => {
						const clicks = parseInt(localStorage['user_'+user.username] ) || 0;
						
						return {
							...user,
							clicks,
						}
					})
					.sort((a, b) => {
						if (a.given_name && b.given_name) {
							return a.given_name.localeCompare(b.given_name, navigatorLanguage, { ignorePunctuation: true });
						}
					});
					
				
				let favouriteUsers = users
					.filter( u => u.clicks)
					 .sort((a, b) => a.clicks < b.clicks ? 1 : -1);
				let normalUsers = users
					.filter( u => !u.clicks);
				
				return [
					...favouriteUsers,
					...normalUsers,
				]; 
			},
		},
		
		data(){
			return {
				loading: null,
				userClicks: {},
			}
		},
		
		methods: {
			onUserClick( user ){
				if ( localStorage['user_'+user.username] ) {
					localStorage['user_'+user.username] = parseInt(localStorage['user_'+user.username] ) + 1;
				}
				else {
					localStorage['user_'+user.username] = 1;
				}
				
				this.$emit('onUserClick', user);
				// this.$router.push({
				// 	name: 'portal-departments', 
				// 	params: { 
				// 		accountId: this.accountId,
				// 		username: user.username,
				// 	}
				// });
			},
			
			async listUsers(){
				try {
					const response = await this.$axios.get(process.env.VUE_APP_API_ENDPOINT +'/portal/users', {
						headers: {
							authorization: this.account.accessToken,
						},
					});
					
					this.$store.commit('setUsers', response.data);
				} 
				catch (e) {
					this.error = { 
						type: 'error', 
						text: e.message || 'Kunne ikke hente brukere'
					};
			
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
		},
		
		async mounted(){
			this.loading = 'fetching';
			await this.listUsers();
			this.loading = null;
		},
	}
</script>